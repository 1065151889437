<script setup lang="ts">
import { computed } from 'vue';
import { clamp } from '@mop/shared/utils/util';
import type { UiProgressTypes, UiProgressSizes } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2Progress',
});

const props = defineProps({
  type: {
    type: String as PropType<UiProgressTypes>,
    default: 'bar',
  },
  size: {
    type: String as PropType<UiProgressSizes>,
    default: 'md',
  },
  progress: {
    type: Number,
    default: 0,
  },
  showLabel: {
    type: Boolean,
    default: false,
  },
});

const progressClampedRef = computed(() => clamp(props.progress, 0, 100));

const circleParamsRef = computed(() => {
  let radius: number, strokeWidth: number;

  switch (props.size) {
    case 'sm':
      radius = 10;
      strokeWidth = 2;
      break;
    case 'md':
      radius = 40;
      strokeWidth = 4;
      break;
    default:
      radius = 60;
      strokeWidth = 6;
  }

  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeOffset = circumference - (progressClampedRef.value / 100) * circumference;

  return {
    radius,
    normalizedRadius,
    strokeWidth,
    circumference,
    strokeOffset,
  };
});
</script>

<template>
  <div v-if="type === 'circle'" :class="['ui-progress--circle', `ui-progress__circle--${size}`]">
    <svg :height="circleParamsRef.radius * 2" :width="circleParamsRef.radius * 2">
      <circle
        class="ui-progress__circle-background"
        :stroke-width="circleParamsRef.strokeWidth"
        fill="transparent"
        :r="circleParamsRef.normalizedRadius"
        :cx="circleParamsRef.radius"
        :cy="circleParamsRef.radius"
      />
      <circle
        class="ui-progress__circle-progress"
        stroke-linecap="round"
        :stroke-dasharray="circleParamsRef.circumference + ' ' + circleParamsRef.circumference"
        :style="{ strokeDashoffset: circleParamsRef.strokeOffset }"
        :stroke-width="circleParamsRef.strokeWidth"
        fill="transparent"
        :r="circleParamsRef.normalizedRadius"
        :cx="circleParamsRef.radius"
        :cy="circleParamsRef.radius"
      />
    </svg>
    <div v-if="showLabel" class="ui-progress__label ui-progress__label--circle">{{ progress }}%</div>
  </div>

  <div v-else class="ui-progress">
    <div class="ui-progress__progress">
      <div
        v-if="progressClampedRef > 0"
        class="ui-progress__progress-done"
        :style="`--width: ${progressClampedRef}%`"
      />
    </div>
    <div v-if="showLabel" class="ui-progress__label">{{ progress }}%</div>
  </div>
</template>

<style lang="scss">
.ui-progress {
  display: flex;
  align-items: center;
  gap: $space-12;
  height: 20px;
}

.ui-progress__progress {
  border-radius: $border-radius-rounded-md;
  background: $color-surface-disabled;
  height: 8px;
  width: 100%;
}

.ui-progress__progress-done {
  width: var(--width);
  height: 8px;
  border-radius: $border-radius-rounded-md;
  background: $color-surface-action-1;
}

.ui-progress__label {
  @include v2-text-style(sm);
  color: $color-text-body-primary;
}

.ui-progress__label--circle {
  @include v2-text-style(md);
  position: absolute;
}

.ui-progress--circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ui-progress__circle--sm .ui-progress__label--circle {
  position: relative;
  margin-left: $space-8;
}

.ui-progress__circle-background {
  stroke: $color-surface-disabled;
}

.ui-progress__circle-progress {
  transition: stroke-dashoffset 1s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  border-radius: 10px;
  stroke: $color-text-body-primary;
}
</style>
